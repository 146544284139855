<template>
  <!-- ====商品信息明细表==== -->
  <div class="goodsInfoDetail">
    <!-- 头部定位区域 -->
    <topOperatingButton :disabled="form.goodsNo == '000'" :isAuditBillBtn="false" :isSubmitAddBtn="false"
      @submitForm="submitForm" @getQuit="getQuit" id="topOperatingButton">
      <template slot="infrontSpecialDiyBtn">
        <el-button size="mini" @click="syncTerminal()" v-if="this.form.goodsId">同步其他端
        </el-button>
      </template>
    </topOperatingButton>
    <!-- 内容区域 -->
    <div class="main" v-loading="boxLoading" element-loading-text="数据加载中">
      <comCard :title="`商品资料`">
        <template #content>
          <div class="content">
            <el-form ref="form" :model="form" label-width="110px" :rules="rules">
              <el-form-item label="商品图片" prop="goodsUrl">
                <AccessoryUpload :title="''" :limit="Number(6)" listType="picture-card" @getFileItems="getGoodsUrls"
                  :fileSize="1" @delFileItems="delGoodsUrl" :fileList="fileList" @sortChange="sortChange"
                  :noneBtnImg="form.goodsImages.length >= 6"></AccessoryUpload>
              </el-form-item>
              <div class="x-f">
                <el-form-item label="商品编码" prop="goodsNo" class="w33">
                  <el-input :disabled="form.goodsNo == '000000' && form.goodsId" v-model="form.goodsNo"
                    placeholder="请输入商品编码" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="商品条码" prop="barcode" class="w33">
                  <el-input v-model="form.barcode" placeholder="请输入商品条码" @input="basicSpec($event, 'barcode')"
                    maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="商品名称" prop="goodsName" class="w33">
                  <el-input v-model="form.goodsName" placeholder="请输入商品名称" maxlength="40"></el-input>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="商品类型" prop="goodsType" class="w33">
                  <el-select @change="goodsTypeChange" v-model="form.goodsType" clearable
                    @blur="$refs.form.validateField('goodsType')" placeholder="请输入商品类型">
                    <el-option v-for="dict in dict.type.goods_type" :key="dict.value" :label="dict.label"
                      :value="dict.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="商品类别" prop="categoryId" class="w33">
                  <!--  :disabled="type == 'update'" -->
                  <treeselect element-loading-text="数据加载中" v-model="form.categoryId" :options="categoryData"
                    :show-count="true" placeholder="请选择商品类别" @blur="treeVerify" @close="treeVerify" />
                </el-form-item>
                <el-form-item label="基本单位" prop="unitName" class="w33">
                  <el-select filterable v-model="form.unitName" placeholder="请选择基本单位"
                    @change="basicSpec($event, 'unitName')" @focus="getGoodsUnitDictList">
                    <el-option v-for="item in unitList" :key="item.dictCode" :label="item.dictLabel"
                      :value="item.dictLabel">
                    </el-option>
                  </el-select>
                  <i class="el-icon-edit marL10" style="
                      color: #85cafc;
                      fontsize: 14px;
                      fontweight: 700;
                      cursor: pointer;
                    " @click="setUnit">新增单位</i>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="商品进价(元)" prop="purPrice" class="w33">
                  <el-input v-model="form.purPrice" placeholder="请输入商品进价(元)" @input="basicSpec($event, 'purPrice')"
                    type="number" maxlength="7"></el-input>
                </el-form-item>
                <el-form-item label="商品售价(元)" prop="salePrice" class="w33">
                  <el-input v-model="form.salePrice" placeholder="请输入商品售价(元)" @input="basicSpec($event, 'salePrice')"
                    type="number" maxlength="7"></el-input>
                </el-form-item>
                <el-form-item label="商品规格" prop="goodsSpec" class="w33">
                  <el-input v-model="form.goodsSpec" placeholder="输入商品规格" @input="basicSpec($event, 'goodsSpec')"
                    maxlength="20"></el-input>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="是否管库存" prop="isManageStock" class="w33">
                  <div class="x-f" style="width: 70%">
                    <el-select :disabled='form.goodsType == 6 || form.goodsType == 7' v-model="form.isManageStock"
                      clearable @blur="$refs.form.validateField('isManageStock')" placeholder="请选择是否管库存"
                      style="width: 25%">
                      <el-option v-for="dict in dict.type.App_is_manage_stock" :key="dict.value" :label="dict.label"
                        :value="dict.value" />
                    </el-select>
                    <span style="margin: 0 10px">仓库</span>
                    <SelectRemote size="medium" v-model="form.storeId" class="flex1" :option="
                        $select({
                          key: 'listStore',
                        }).option
                      " />
                  </div>
                </el-form-item>
                <el-form-item label="储存方式" prop="depositWay" class="w33">
                  <el-input v-model="form.depositWay" placeholder="请输入储存方式" maxlength="40"></el-input>
                </el-form-item>
                <el-form-item label="计价方式" prop="pricingMode" class="w33">
                  <el-select v-model="form.pricingMode" clearable @blur="$refs.form.validateField('pricingMode')"
                    placeholder="请选择计价方式">
                    <el-option v-for="dict in dict.type.pricing_mode" :key="dict.value" :label="dict.label"
                      :value="dict.value" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="商品状态" prop="status" class="w33">
                  <el-select v-model="form.status" clearable placeholder="请选择商品状态">
                    <el-option label="正常" :value="0"></el-option>
                    <el-option label="停用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="商品品牌" prop="brandId" class="w33">
                  <el-select v-model="form.brandId" clearable placeholder="请选择商品品牌" element-loading-text="数据加载中">
                    <el-option v-for="item in brandData" :key="item.brandId" :label="item.brandName"
                      :value="item.brandId" />
                  </el-select>
                </el-form-item>
                <el-form-item label="税率(%)" prop="taxRate" class="w33">
                  <el-input v-model="form.taxRate" placeholder="请输入税率(%)" type="number" maxlength="7"></el-input>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="保质期天数" prop="qualityDays" class="w33">
                  <div class="x-f" style="width: 70%">
                    <el-input v-model="form.qualityDays" placeholder="保质期天数" type="number" maxlength="7"
                      style="width: 35%"></el-input>
                    <span style="margin: 0 10px">预警天数</span>
                    <el-input v-model="form.qualityAlertDays" placeholder="预警天数" type="number" maxlength="7"
                      class="flex1"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="商品重量(kg)" prop="weight" class="w33">
                  <el-input v-model="form.weight" placeholder="请输入商品重量(kg)" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="供应商" prop="partnerId" class="w33">
                  <SelectRemote v-model="form.partnerId" :option="
                      $select({
                        key: 'listSupplier',
                      }).option
                    " />
                </el-form-item>
              </div>
              <div class="x-f plurality">
                <div class="w33">
                  <div class="x-f" style="width:calc(70% + 32px)">
                    <el-form-item label="商品排序" prop="sortNo" style="width:51%">
                      <el-input v-model="form.sortNo" placeholder="请输入商品排序" type="number" maxlength="7"></el-input>
                    </el-form-item>
                    <el-form-item label="商品型号" prop="goodsModel" class="flex1" label-width="80px">
                      <el-input v-model="form.goodsModel" placeholder="输入商品型号" maxlength="20"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="w33 x-x">
                  <div class="x-f" style="width:calc(70% + 32px)">
                    <el-form-item label="最新进价(含税)" prop="lastPurTaxPrice" style="width: 46%">
                      <el-input disabled v-model="form.lastPurTaxPrice" placeholder="含税进价" />
                    </el-form-item>
                    <el-form-item label="最新进价(不含税)" prop="lastPurNotaxPrice" class="flex1" label-width="130px">
                      <el-input disabled v-model="form.lastPurNotaxPrice" placeholder="不含税进价" />
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="最新成本价" prop="lastCost" class="w33">
                  <el-input disabled v-model="form.lastCost" placeholder="最新成本价(不含税)" />
                </el-form-item>
              </div>
              <div class="x-f switch">
                <el-form-item label="允许改价" prop="weight">
                  <el-switch v-model="form.isEnableBargaining"></el-switch>
                </el-form-item>
                <el-form-item label="允许折扣" prop="weight">
                  <el-switch v-model="form.isEnableDiscount"></el-switch>
                </el-form-item>
                <el-form-item label="允许赠送" prop="weight">
                  <el-switch v-model="form.isEnableGive"></el-switch>
                </el-form-item>
                <el-form-item label="允许采购" prop="weight">
                  <el-switch v-model="form.isEnablePurchase"></el-switch>
                </el-form-item>
                <el-form-item label="允许销售" prop="weight">
                  <el-switch v-model="form.isEnableSale"></el-switch>
                </el-form-item>
                <el-form-item label="允许积分" prop="weight">
                  <el-switch v-model="form.isEnableIntegral"></el-switch>
                </el-form-item>
                <el-form-item label="允许多单位分货" prop="weight">
                  <el-switch v-model="form.isMulitUnitDistribute"></el-switch>
                </el-form-item>
              </div>
              <!-- 规格列表 -->
              <el-form-item label="规格列表" prop="goodsUnit" class="specList">
                <div class="goodsSpecList">
                  <div class="specItem">
                    <goodsSpecItem :goodsId="form.goodsId" :goodsUnit="form.goodsUnit"
                      @handleAddMachiningGroup="handleAddMachiningGroup" @handleAddMachining="handleAddMachining"
                      :machiningData="allMachiningGroupData" @delGoodsSpec="handleDelGoodsSpec" @setUnit="setUnit"
                      :unitList="unitList" @openSelectGoods="openSelectGoods"
                      @handleCollocationTableRow="handleCollocationTableRow"
                      @handleNutrientTableRow="handleNutrientTableRow" @collocationTableKeyup="collocationTableKeyup">
                    </goodsSpecItem>
                  </div>
                  <div class="btn marT10">
                    <el-button size="mini" class="el-icon-plus" @click="handleAddGoodsSpec">新建规格
                    </el-button>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="商品备注" prop="remark" class="w60">
                <el-input type="text" v-model="form.remark" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" />
              </el-form-item>
              <el-form-item label="商品备注2" prop="remark2" class="w60">
                <el-input type="text" v-model="form.remark2" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" />
              </el-form-item>
            </el-form>
          </div>
        </template>
      </comCard>
    </div>
    <!-- 商品属性值列表弹出框 -->
    <selectMachining :OpenMachining.sync="OpenMachining" :machiningData="machiningData" @getMachining="pitchMachining">
    </selectMachining>
    <!-- 编辑单位弹出框 -->
    <el-dialog title="商品单位设置" :visible.sync="OpenUnit" width="30%" class="goodsUnit" :lock-scroll="false"
      v-dialogDrag="true">
      <div class="top">
        <div class="unitTotal">
          共有<span class="num">{{ unitList.length }}</span>种单位
        </div>
        <div class="addUnit">
          <input type="text" class="ADDinput" v-model="addUnit" placeholder="请输入新单位" />
          <span class="ADDbtn" @click="setUnitList(addUnit)">新增</span>
        </div>
      </div>
      <div class="unitTable">
        <div class="table">
          <div class="item" v-for="(item, index) in unitList" :key="item.dictCode">
            <div class="content">
              <el-input ref="unitFocus" class="unit" type="text" v-model="item.dictValue"
                :readonly="!(readonly === index)" />
              <div class="set">
                <i v-show="showSave === index" class="el-icon-circle-check"
                  @click="updateUnit(item.dictCode, index)"></i>
                <i v-show="!(showSave === index)" class="el-icon-edit" @click="unitGetFocus(index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 选择商品弹窗 -->
    <SelectGoods :OpenGoods.sync="OpenGoods" @getGoodsIds="pitchGoods" />
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import SelectGoods from "@/views/components/SelectGoods/SelectGoods"; //选择商品弹窗组件
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue"; //上传图片/附件组件
import Treeselect from "@riophae/vue-treeselect"; //树形结构下拉框
import "@riophae/vue-treeselect/dist/vue-treeselect.css"; //树形结构下拉框样式
import goodsSpecItem from "./components/goodsSpecItem.vue"; //规格列表每一项组件
import selectMachining from "@/views/components/selectMachining"; //属性值选择框组件
import { getData } from "@/utils/public"; //获取下拉框数据
import { listCategoryTree } from "@/api/goods/category"; // 商品类别 API
import {
  getGoodsUnitDictData, //单位字典api
  saveGoodsUnitDictData, //新增单位字典
  updateGoodsUnitDictData, //编辑单位字典
  getGoodsUnitId, //多规格单位id
  addGoods, //新增商品
  updateGoods, //修改商品
  getGoodsDetail, //商品详情
  listGoods, //商品列表
  getMaxGoodsUnitSn, //获取最大规格编号
} from "@/api/goods/goods";
// import { getBillNo } from '@/api/codeRule'
import { getGoodsAutoNo } from "@/api/goods/goods"; //接口api  自动生成编号
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import { limitInputlength } from "@/utils/index.js"; //保留几位小数
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import roureMinxin from "@/utils/roure-minxin";
export default {
  name: "GoodsInfoDetail",
  mixins: [roureMinxin],
  dicts: [
    "goods_type", //商品类型
    "pricing_mode", //计价方式
    "App_is_manage_stock", //是否管库存
  ],
  components: {
    AccessoryUpload,
    Treeselect,
    goodsSpecItem,
    selectMachining,
    SelectGoods,
    topOperatingButton,
    SelectRemote,
    Dialog,
  },

  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "同步其他端",
        width: 600,
        show: false,
        type: "SyncTerminal",
      },
      isEidtBarcode: false, //是否编辑条码
      //基本单位第一次改变
      isFirstTime: false,
      //整体页面加载动画
      boxLoading: false,
      //新增/编辑类型
      type: "商品资料-新增",
      //标题
      title: undefined,
      //存储原始form数据
      prevForm: {},
      //商品图片数组(回显)
      fileList: [],
      //类别数据
      categoryData: [],
      //品牌数据
      brandData: [],
      //所有属性组数据
      allMachiningGroupData: [],
      //根据属性组id获取的属性值数据
      machiningData: [],
      //字典单位数据
      unitList: [],
      //是否可编辑单位状态
      readonly: true,
      //判断显示隐藏编辑删除多规格单位
      showSave: false,
      //新增单位
      addUnit: undefined,
      //图片阿里云上传地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      //商品属性值列表弹出框开关
      OpenMachining: false,
      //编辑单位弹出框开关
      OpenUnit: false,
      //标识当前某个规格某个属性组点开的属性弹框
      curSpec: {
        specIndex: undefined,
        mGIndex: undefined,
      },
      //选择商品弹窗开关
      OpenGoods: false,
      //规格列表下标 从某个规格点出来的选择商品弹窗 后续选完商品根据这个下标赋值
      curSpecIndex: undefined,
      categoryNumber: undefined, // 商品类别编号
      //表单参数
      form: {
        goodsId: undefined, //商品id
        goodsImages: [], //商品图片
        goodsName: undefined, //商品名称
        goodsType: "1", //商品类型
        categoryId: undefined, //商品分类
        goodsModel: undefined, //商品型号
        goodsNo: undefined, //商品编号
        barcode: undefined, //条码
        sortNo: undefined, //排序
        taxRate: undefined, //税率
        unitId: 0, //基本单位id
        unitName: undefined, //基本单位名称
        goodsSpec: undefined, //商品规格
        qualityDays: undefined, //保质期天数
        weight: undefined, //重量
        purPrice: undefined, //进价
        salePrice: undefined, //售价
        pricingMode: "0", //计价方式
        isManageStock: "1", //是否管库存
        status: 0, //状态
        remark: undefined, //备注
        brandId: undefined, //品牌
        isEnableBargaining: false, //允许改价
        isEnableDiscount: true, //允许折扣
        isEnableGive: true, //允许赠送
        isEnablePurchase: true, //允许采购
        isEnableSale: true, //允许销售
        isEnableIntegral: true, //允许积分
        //规格列表
        goodsUnit: [
          {
            //规格信息
            goodsUnits: [
              {
                barcode: undefined, //条码
                goodsSpec: undefined, //规格
                purPrice: undefined, //单位进价
                salePrice: undefined, //单位售价
                referUnitAmount: undefined, //参考单位比例系数
                referUnitId: undefined, //参考单位id
                unitId: undefined, //多规格单位id
                unitName: undefined, //单位名称
                noConvert: 0,
              },
            ],
            //属性数组
            machiningGroups: [],
            //搭配商品
            collocationItems: [],
            goodsUnitNutrientReqList: [], //搭配营养物质
          },
        ],
      },
      // 商品表单校验
      rules: {
        barcode: [{ required: true, message: "请输入条码", trigger: "blur" }],
        categoryId: [{ required: true, message: "请选择商品类别", trigger: "change" }],
        goodsName: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        goodsNo: [{ required: true, message: "请输入商品编号", trigger: "blur" }],
        goodsType: [{ required: true, message: "请选择商品类型", trigger: "change" }],
        isManageStock: [
          { required: true, message: "请选择是否管库存", trigger: "change" },
        ],
        pricingMode: [{ required: true, message: "请选择计价方式", trigger: "change" }],
        purPrice: [
          {
            pattern: /^(?!0+$)/,
            message: "商品进价不能为0",
            trigger: "blur",
            required: true,
          },
          { required: true, message: "请输入商品进价", trigger: "blur" },
        ],
        salePrice: [{ required: true, message: "请输入商品售价", trigger: "blur" }],
        unitName: [
          {
            required: true,
            message: "请选择基本单位",
            trigger: ["blur", "change"],
          },
        ],
        goodsSpec: [{ required: true, message: "请输入商品规格", trigger: "blur" }],
      },
    };
  },
  watch: {
    //侦听商品id 区分新增/修改 赋值标题
    title: {
      handler(val) {
        this.title = val ? "商品资料-修改" : "商品资料-新增";
      },
    },
    //侦听基本单位
    "form.unitName": {
      async handler(newVal) {
        //第一次改变
        if (this.isFirstTime) {
          this.$set(this.form, "goodsSpec", newVal);
          this.basicSpec(newVal, "goodsSpec");
        }
      },
      // immediate: true
    },
  },
  // // 渲染该组件前调用这个路由钩子
  // beforeRouteEnter (to, from, next) {
  //   //因为当守卫执行前,组件实例还没创建
  //   //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
  //   //通过'vm'才能访问组件this实例
  //   next(async vm => {
  //     //页面加载动画
  //     vm.boxLoading = true
  //     //初始化数据
  //     await vm.reset()
  //     await vm.init()
  //     //根据商品id 获取商品详情
  //     if (to.query.type === 'add') {
  //       //新增
  //       vm.type = 'add'
  //       vm.form.categoryId = to.query.categoryId
  //       vm.categoryNumber = to.query.categoryNumber
  //       vm.isFirstTime = true
  //       await vm.getBillno()
  //     } else if ((vm.type = 'update')) {
  //       //修改
  //       //初始化数据
  //       //根据商品id 赋值商品详情
  //       await vm.handleUpdate(to.query.goodsId)
  //     }
  //     //存储form原始数据 以便后续判断是否修改了页面内容
  //     vm.prevForm = JSON.stringify(vm.form)
  //     vm.boxLoading = false
  //   })
  // },
  methods: {
    goodsTypeChange(val) {
      if (val == '6' || val == '7') {
        this.$set(this.form, 'isManageStock', '0')
      } else {
        this.$set(this.form, 'isManageStock', '1')
      }
    },
    sortChange(value) {
      const urlOrder = value.map((item, index) => ({ url: item.url, index }));
      function getSortOrder(accessUrl) {
        return urlOrder.findIndex((item) => item.url === accessUrl);
      }
      this.form.goodsImages.sort(
        (a, b) => getSortOrder(a.accessUrl) - getSortOrder(b.accessUrl)
      );
    },
    limitInputlength,
    //页面加载钩子函数
    async onPageShow() {
      //页面加载动画
      this.boxLoading = true;
      // 访问组件实例 this 中的数据和方法
      if (this.$route.query.type === "update") {
        if (!this.form.goodsId || this.form.goodsId != this.$route.query.goodsId) {
          //初始化数据
          await this.reset();
          await this.init();
          //根据商品id 赋值商品详情
          //修改
          await this.handleUpdate(this.$route.query.goodsId);
        }
      } else if (this.$route.query.type === "add") {
        if (
          !this.form.timestampId ||
          this.form.timestampId != this.$route.query.timestampId
        ) {
          //初始化数据
          await this.reset();
          await this.init();
          //新增
          this.type = "add";
          this.form.categoryId = this.$route.query.categoryId;
          this.categoryNumber = this.$route.query.categoryNumber;
          this.isFirstTime = true;
          await this.getBillno();
          this.form.timestampId = this.$route.query.timestampId;
        }
      }
      this.boxLoading = false;
    },
    async init() {
      //获取下拉框数据
      await this.getselectData();
      await this.getGoodsUnitDictList();
      await this.getMachiningData();
      // 获取商品类别数据
      await this.getCategoryData();
    },
    async getCategoryData() {
      // const queryParams = {
      //   isDisableRoot: true // 将 isDisableRoot 参数设置为 true
      //   // 其他查询参数...
      // }
      const { data } = await listCategoryTree(); // 获取商品类别数据
      this.categoryData = data;
    },
    // processCategoryData(data) {
    //   // 假设isDisableRoot是数据中的一个属性，表示是否隐藏根节点
    //   const isDisableRoot = true; // 从后端获取的参数或者其他逻辑来设置

    //   // 根据isDisableRoot来决定是否隐藏根节点
    //   if (isDisableRoot) {
    //     // 返回不包含根节点的数据
    //     return data[0].children; // 这里根据实际数据结构来调整
    //   } else {
    //     // 不隐藏根节点，返回原始数据
    //     return data;
    //   }
    // },
    // 获取订单编号
    async getBillno() {
      // const formData = new FormData()
      // formData.append('billType', '100101')
      // formData.append('fieldPrefix', this.categoryNumber)
      getGoodsAutoNo(this.form.categoryId).then((res) => {
        this.form.goodsNo = res.data;
        this.form.barcode = this.form.goodsNo;
        this.basicSpec(this.form.barcode, "barcode");
      });
    },
    //获取上传的图片数据
    getGoodsUrls(data) {
      this.form.goodsImages.push(data);
    },
    //删除上传的图片
    delGoodsUrl(file) {
      console.log("file", file);
      console.log("goodsId", this.form.goodsId);
      console.log("goodsImages", this.form.goodsImages);
      // console.log('file', file)
      this.form.goodsImages = this.form.goodsImages.filter((v) => {
        if (this.form.goodsId && v.accessUrl !== file.url) {
          return v;
        } else if (file.response && v.filePath !== file.response.data.filePath) {
          return v;
        }
      });
      console.log("goodsImages", this.form.goodsImages);
      // this.form.goodsImages.forEach((item, index) => {
      //   if (item.attachFileUrl == value.url) {
      //     console.log('进来')
      //     this.posImageForm.carouselUrls.splice(index, 1)
      //   }
      // })
    },
    //规格列表同步基本规格信息
    basicSpec(event, name) {
      if (name === "barcode") {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], "barcode", event);
      }
      if (name === "purPrice") {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], "purPrice", event);
        this.limitInputlength(event, "form.purPrice");
      }
      if (name === "salePrice") {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], "salePrice", event);
        this.limitInputlength(event, "form.salePrice");
      }
      if (name === "goodsSpec") {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], "goodsSpec", event);
      }
      if (name === "unitName") {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], "unitName", event);
        this.$set(this.form.goodsUnit[0].goodsUnits[0], "unitId", 0);
      }
    },
    //获取下拉框数据
    async getselectData() {
      //   this.categoryData = await getData("goodsCategory"); //商品类别数据
      this.brandData = await getData("goodsBrand"); //商品品牌数据
    },
    //获取商品属性数据
    async getMachiningData() {
      this.allMachiningGroupData = await getData("goodsMachining"); //商品属性数据
    },
    //获取字典单位数据
    getGoodsUnitDictList() {
      getGoodsUnitDictData().then((response) => {
        this.unitList = response.data;
      });
    },
    //点击设置单位
    async setUnit() {
      console.log("111111");
      await this.getGoodsUnitDictList();
      this.OpenUnit = true;
    },
    //添加单位字典
    setUnitList(addUnit) {
      if (addUnit.trim()) {
        //判断新增的单位是否跟单位数据重复
        const bool = this.unitList.some((item) => item.dictValue === addUnit);
        if (!bool) {
          saveGoodsUnitDictData(addUnit).then((response) => {
            this.getGoodsUnitDictList();
          });
        } else {
          this.$message.error("单位名称重复,请重新输入!");
        }
      } else {
        this.$message.error("请输入单位名称");
      }
      this.addUnit = "";
    },
    //编辑字典单位事件
    updateUnit(dictCode, index) {
      updateGoodsUnitDictData({
        dictCode: dictCode,
        dictLabel: this.unitList[`${index}`].dictValue,
      }).then((response) => {
        this.$message.success("编辑单位成功");
        this.showSave = false;
        this.readonly = false;
        this.getGoodsUnitDictList();
      });
    },
    //编辑字典单位获得焦点事件
    unitGetFocus(index) {
      this.showSave = index;
      this.readonly = index;
      this.$nextTick(() => {
        this.$refs.unitFocus[`${index}`].focus();
      });
    },
    //新增/修改表单tree下拉组件单独校验事件
    treeVerify() {
      this.$nextTick(() => {
        this.$refs.form.validateField("categoryId");
      });
    },
    //新建商品规格
    async handleAddGoodsSpec() {
      // 校验基本信息是否输入完整
      const isFormValid = await this.validateForm();
      if (!isFormValid) {
        return this.$message.warning("请把带红星的基本信息输入完整");
      }

      // 新增一个多规格
      let barcode = "";
      if (this.form.goodsId && !this.isEidtBarcode) {
        const res = await getMaxGoodsUnitSn(this.form.goodsId);
        barcode = res.data;
        this.isEidtBarcode = true;
      } else {
        barcode = await this.generateBarcode();
      }

      // 获取一个标识的多规格单位id
      await this.addNewGoodsUnit(barcode);

      // 等待获取 goodsUnit 字典列表
      await this.getGoodsUnitDictList();

      // 新增规格里属性数据
      this.getMachiningData();
    },

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs["form"].validate((valid) => {
          resolve(valid);
        });
      });
    },

    generateBarcode() {
      let baseBarcode = this.form.barcode; // 假设这是你的基础条码
      let suffix = 1; // 初始后缀为 1
      let barcode = "";

      if (this.form.goodsUnit.length > 0) {
        // 查找当前 goodsUnit 中最大的后缀
        let maxSuffix = 0;
        this.form.goodsUnit.forEach((item) => {
          const currentSuffixStr = item.goodsUnits[0].barcode.split("-")[1]; // 假设条码格式为 "基础条码-后缀"
          if (currentSuffixStr) {
            const currentSuffix = parseInt(currentSuffixStr, 10);
            if (!isNaN(currentSuffix) && currentSuffix > maxSuffix) {
              maxSuffix = currentSuffix;
            }
          }
        });

        // 计算新的后缀
        suffix = maxSuffix + 1;

        // 保持基础条码不变，只改变后缀
        barcode = `${baseBarcode}-${suffix.toString().padStart(2, "0")}`;
      } else {
        // 如果没有 goodsUnit，直接使用基础条码并添加后缀
        barcode = `${baseBarcode}-${suffix.toString().padStart(2, "0")}`;
      }

      return barcode;
    },
    async addNewGoodsUnit(barcode) {
      // 假设 getGoodsUnitId 返回一个 Promise，它解析为一个包含 id 的对象
      const { id } = await getGoodsUnitId();

      this.form.goodsUnit.push({
        goodsUnits: [{ unitId: id, barcode, noConvert: 0 }],
        machiningGroups: [],
        collocationItems: [],
        goodsUnitNutrientReqList: [], // 搭配营养物质
      });
    },
    //删除商品规格
    handleDelGoodsSpec(index) {
      console.log(index);
      //判断该商品规格是否被引用 已被引用提醒用户先删除引用的规格
      let bool = this.form.goodsUnit.some(
        (v) =>
          v.goodsUnits[0].referUnitId === this.form.goodsUnit[index].goodsUnits[0].unitId
      );
      console.log(bool);
      if (bool) {
        this.$message.warning("该商品规格已被引用,请先删除被引用商品规格");
      } else {
        //删除多规格
        this.form.goodsUnit.splice(index, 1);
      }
    },
    //操作商品属性组(新增/删除) type新增/删除  specListIndex 规则列表下标   MIndex 属性组下标
    handleAddMachiningGroup({ type, specListIndex, MGIndex }) {
      let machiningGroupsData = this.form.goodsUnit[specListIndex].machiningGroups;
      if (type === "add") {
        machiningGroupsData.push({});
      } else {
        //删除属性组
        machiningGroupsData.splice(MGIndex, 1);
      }
    },
    //操作商品属性值(新增/删除) type新增/删除  specListIndex 规则列表下标 MGIndex 属性组下标  MIndex 属性下标  id 属性组id
    handleAddMachining({ type, specListIndex, MGIndex, MIndex, id }) {
      if (type === "add") {
        console.log(id);
        let machiningGroupId = id; //传过来的属性分组id 获取属性值数据
        this.allMachiningGroupData.forEach((v) => {
          if (v.machiningGroupId === machiningGroupId) {
            this.curSpec.specIndex = specListIndex; //当前规格下标
            this.curSpec.mGIndex = MGIndex; //当前属性组下标
            this.machiningData = v.machiningItems; //当前属性组的属性值
            this.OpenMachining = true;
          }
        });
      } else {
        //删除属性值
        this.form.goodsUnit[specListIndex].machiningGroups[MGIndex].machiningItems.splice(
          MIndex,
          1
        );
      }
    },
    //选中的属性值数据
    pitchMachining(data) {
      let machiningData = this.form.goodsUnit[this.curSpec.specIndex].machiningGroups[
        this.curSpec.mGIndex
      ];
      //当属性值数组不存在时,初始化一个空数组
      if (!machiningData.machiningItems) {
        this.$set(machiningData, "machiningItems", []);
      } else {
        //当属性数组有值时,过滤掉重复的属性值
        for (let i = 0; machiningData.machiningItems.length > i; i++) {
          data = data.filter(
            (v) => v.machiningId !== machiningData.machiningItems[i].machiningId
          );
        }
      }
      //选中的属性值追加在后面
      machiningData.machiningItems.push(...data);
    },
    //规格列表搭配商品 打开选择商品弹窗  index当前规格下标
    openSelectGoods(index) {
      this.curSpecIndex = index;
      this.OpenGoods = true;
    },
    //选择商品弹窗 选中的商品
    pitchGoods(data) {
      // 过滤表格里空行
      this.form.goodsUnit[this.curSpecIndex].collocationItems = this.form.goodsUnit[
        this.curSpecIndex
      ].collocationItems.filter((v) => v.goodsId);
      this.form.goodsUnit[this.curSpecIndex].collocationItems.push(...data);
    },
    //操作搭配商品表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleCollocationTableRow({ type, specIndex, index }) {
      if (type === "push") {
        this.form.goodsUnit[specIndex].collocationItems.push({});
      } else {
        this.form.goodsUnit[specIndex].collocationItems.splice(index, 1);
      }
    },
    //操作搭配营养物质表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleNutrientTableRow({ type, specIndex, index }) {
      if (type === "push") {
        this.form.goodsUnit[specIndex].goodsUnitNutrientReqList.push({});
      } else {
        this.form.goodsUnit[specIndex].goodsUnitNutrientReqList.splice(index, 1);
      }
    },
    //搭配商品表格 商品编码键盘回车事件  val输入的值 specIndex规格列表下标  index当前表格行下标
    collocationTableKeyup({ val, specIndex, index }) {
      console.log(val);
      //判断输入值是否为空
      if (val) {
        listGoods({ goodsNo: val }).then(async (response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(
              this.form.goodsUnit[specIndex].collocationItems,
              index,
              response.rows[0]
            );
          } else {
            this.OpenGoods = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        this.OpenGoods = true;
      }
    },
    //编辑事件  根据商品id获取商品详情
    async handleUpdate(goodsId) {
      const res = await getGoodsDetail(goodsId);
      this.form = res.data;
      this.categoryNumber = this.form.categoryNo;
      console.log("商品详情---", this.categoryNumber, this.form.categoryNo);
      //后端返回的数据进行处理
      this.transitionData();
      this.boxLoading = false;
    },
    //从后端返回的数据进行处理
    transitionData() {
      //获取商品属性数据
      this.getMachiningData();
      //商品图片转换
      this.fileList = [];
      this.form.goodsImages.forEach((v) => {
        this.fileList.push({ url: v.accessUrl });
      });
      //商品规格列表 搭配商品表格数组初始化为空数组
      this.form.goodsUnit = this.form.goodsUnit.map((v) => {
        if (v.collocationItems === null) v.collocationItems = [];
        return v;
      });
      //商品规格列表 搭配商品表格数组初始化为空数组
      this.form.goodsUnit = this.form.goodsUnit.map((v) => {
        if (v.goodsUnitNutrientReqList === null) v.goodsUnitNutrientReqList = [];
        return v;
      });
    },
    //保存按钮事件 保存并新增按钮  bool区分保存/保存并新增
    submitForm(bool) {
      bool = !bool;
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate(async (valid) => {
          //表单校验
          if (valid) {
            if (this.form.categoryId == 0) return this.$message.error("请选择其他分类");
            //过滤商品规格空属性
            this.form.goodsUnit.map((item) => {
              item.machiningGroups = item.machiningGroups.filter(
                (v) => v.machiningGroupId
              );
            });
            //校验选中属性组是否选了属性值
            let machiningPitch = this.form.goodsUnit.every((item) => {
              let bool = item.machiningGroups.some((v) => {
                if (v.machiningGroupId) v.machiningItems ? true : false;
              });
              return bool;
            });
            if (machiningPitch) this.$message.error("请选择属性值");
            let tipName; //提交成功后的提示文本
            if (this.form.goodsId != undefined) {
              const res = await updateGoods(this.form);
              this.reset();
              this.form = res.data;
              tipName = "修改商品成功";
              //后端返回的数据进行处理
              this.transitionData();
            } else {
              const res = await addGoods(this.form);
              this.form = res.data;
              tipName = "新增商品成功";
              //后端返回的数据进行处理
              this.transitionData();
            }
            //保存并新增
            if (bool) {
              //拿到请空前的类别id
              const categoryId = JSON.parse(JSON.stringify(this.form.categoryId));
              //初始化数据
              this.reset();
              this.form.categoryId = categoryId;
              this.getBillno();
              this.$message.success("保存并新增成功");
            } else {
              //新增/修改的提示
              this.$message.success(tipName);
            }
            resolve();
          } else {
            this.$message.error("请把带有红星的必填项输入完整");
          }
        });
      });
    },
    //退出按钮事件
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/goods/goods/info" });
    },
    // 同步其他端
    async syncTerminal() {
      try {
        await this.submitForm(true);
        this.dialogOptions.show = true;
        this.dialogOptions.formData = {
          goodsId: this.form.goodsId,
        };
      } catch (err) { }
    },
    //重置初始化数据
    reset() {
      //表单参数
      this.form = {
        goodsId: undefined, //商品id
        goodsImages: [], //商品图片
        goodsName: undefined, //商品名称
        goodsType: "1", //商品类型
        categoryId: undefined, //商品分类
        goodsModel: undefined, //商品型号
        goodsNo: undefined, //商品编号
        barcode: undefined, //条码
        sortNo: undefined, //排序
        taxRate: undefined, //税率
        unitId: 0, //基本单位id
        unitName: undefined, //基本单位名称
        goodsSpec: undefined, //商品规格
        qualityDays: undefined, //保质期天数
        weight: undefined, //重量
        purPrice: undefined, //进价
        salePrice: undefined, //售价
        pricingMode: "0", //计价方式
        isManageStock: "1", //是否管库存
        status: 0, //状态
        remark: undefined, //备注
        brandId: undefined, //品牌
        isEnableBargaining: false, //允许议价
        isEnableDiscount: true, //允许折扣
        isEnableGive: true, //允许赠送
        isEnablePurchase: true, //允许采购
        isEnableSale: true, //允许销售
        isEnableIntegral: true, //允许积分
        //规格列表
        goodsUnit: [
          {
            //规格信息
            goodsUnits: [
              {
                barcode: undefined, //条码
                goodsSpec: undefined, //规格
                purPrice: undefined, //单位进价
                salePrice: undefined, //单位售价
                referUnitAmount: undefined, //参考单位比例系数
                referUnitId: undefined, //参考单位id
                unitId: undefined, //多规格单位id
                unitName: undefined, //单位名称
                noConvert: 0,
              },
            ],
            //属性数组
            machiningGroups: [],
            //搭配商品
            collocationItems: [],
            goodsUnitNutrientReqList: [], //搭配营养物质
          },
        ],
      };
      //根据属性组id获取的属性值数据
      this.machiningData = [];
      //字典单位数据
      this.unitList = [];
      //标识当前某个规格某个属性组点开的属性弹框
      this.curSpec = {
        specIndex: undefined,
        mGIndex: undefined,
      };
      //商品图片数组
      this.fileList = [];
      //选择商品弹窗开关
      this.OpenGoods = false;
      //规格列表下标 从某个规格点出来的选择商品弹窗 后续选完商品根据这个下标赋值
      this.curSpecIndex = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
// 整体页面
.goodsInfoDetail {
  color: #333333;
  padding-top: 35px;
  background-color: #eaeaea;
  .main {
    .el-form-item {
      margin-bottom: 15px;

      //宽度33%
      &.w33 {
        width: 33%;

        .el-input,
        .el-select,
        .vue-treeselect {
          width: 70%;
        }
      }

      //宽度60%
      &.w60 {
        width: 60%;
      }

      //规格列表
      &.specList {
        width: 100%;
      }

      .el-input,
      .vue-treeselect,
      .el-select {
        width: 100%;
      }
    }

    .content {
      padding: 0 20px;
    }

    //开关行
    .switch {
      .el-form-item {
        margin-bottom: 0px;
        width: 14%;
      }
    }
  }

  // 商品属性列表弹出框
  .machiningDialog {
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #f5f5f5;
      padding: 10px !important;
    }

    ::v-deep .el-dialog__header {
      padding: 10px !important;
    }

    ::v-deep .el-dialog__body {
      padding: 20px !important;
      padding-bottom: 0 !important;
    }

    .content {
      height: 400px;
      margin: 10px 0;

      .el-scrollbar {
        height: 100%;

        /* element滚动条组件 隐藏水平滚动条 */
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        ::v-deep .is-horizontal {
          display: none;
        }
      }
    }
  }

  //字典单位弹出框样式
  .goodsUnit {
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      height: 40px;
      line-height: 40px;

      .unitTotal {
        .num {
          color: #0088dd;
          margin: 0 2px;
        }
      }

      .addUnit {
        width: 40%;
        border: 1px solid #0096cb;
        border-radius: 5px;

        .ADDinput {
          width: 70%;
          border: none;
          outline: none;
          text-align: center;
        }

        .ADDbtn {
          display: inline-block;
          text-align: center;
          background-color: #0088dd;
          color: #fff;
          width: 30%;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .unitTable {
      height: 33vh;
      overflow: auto;
      border-bottom: 1px solid #ccc;
      border: 1px solid #ccc;
      border-radius: 5px;

      // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
      &::-webkit-scrollbar {
        width: 3px;
      }

      // 滚动条里面默认的小方块,自定义样式
      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 2px;
      }

      // 滚动条里面的轨道
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      .table {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .item {
          margin: 5px 5px;
          width: 23%;
          height: 50px;
          line-height: 50px;
          border: 1px solid #ccc;
          border-radius: 5px;
          display: flex;

          .content {
            display: flex;
            width: 100%;
            padding: 0 5px;
            align-items: center;
            .unit {
              width: 100%;
              flex: 7;
              padding-right: 5px;
              border: none;
              outline: none;
            }
            .set {
              flex: 3;
              cursor: pointer;
              display: flex;

              i {
                margin-right: 5px;
                color: #0088dd;
              }
            }
          }
        }
      }
    }
  }
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plurality {
  .w33 {
    width: 33%;
  }
}
</style>
